import React from 'react';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../containers/SaasModern/Documentation';
import ribbon_customize from '../../../../../../common/src/assets/image/ribbon_customize.png';
import task_pane_customize_template from '../../../../../../common/src/assets/image/task_pane_customize_template.png';
import customize_1 from '../../../../../../common/src/assets/image/customize_1.png';
import customize_2 from '../../../../../../common/src/assets/image/customize_2.png';
import customize_3 from '../../../../../../common/src/assets/image/customize_3.png';
import customize_5 from '../../../../../../common/src/assets/image/customize_5.png';
import customize_6 from '../../../../../../common/src/assets/image/customize_6.png';
import customize_7 from '../../../../../../common/src/assets/image/customize_7.png';
import customize_advanced_1 from '../../../../../../common/src/assets/image/customize_advanced_1.png';
import ContactForm from 'common/src/components/ContactForm';

const Documentation = () => {
  const content = (
    <div>
      <p>
        PrintableCal comes installed with a number of attractive templates.
        However, there may be cases where you'd like to customize a template to
        include things like business logos, contact information, photos,
        different colors, different layouts, etc. PrintableCal enables you to do
        this, and then re-use your customized template again and again.
      </p>
      <p>
        Think of all the time this could save when you need to create or update
        your calendar. Instead of editing by hand, the template pulls in all
        your schedule data and formats it based on how your template was
        defined. PrintableCal automates the process and can complete in a matter
        of seconds what would normally take hours of manual data entry.
      </p>
      <h2>Creating a Custom Template - A Walkthrough</h2>
      <p>
        The steps below explain how a fictional business named Bob's Bicycles
        could customize the default Month template to include their contact
        information and logo. This calendar is printed and distributed to their
        customers. Many business have similar needs, or you might think of other
        ways to take the functionality described below and adapt it to your own
        requirements.
      </p>
      <ol className="numbered">
        <li>
          Templates can only be customized in Excel. However, customized
          templates can be used when generating calendars in Word. The first
          step is to click the <strong>Customize </strong>button on the
          PrintableCal tab of the Excel Ribbon.
          <br />
          <Image alt="" src={ribbon_customize} style={{ width: 790 }} />
        </li>
        <li>
          After clicking the Customize button, the Task Pane will appear with a
          list of currently installed templates. Templates are listed by type,
          such as Agenda or Month. Click a template type to expand its list of
          templates. Select the template you'd like to use as a starting point
          for your customization and then click the <strong>Customize</strong>{' '}
          button. Note, when you save the customization, you can choose to save
          to a new template if you don't want to overwrite the original. In this
          example scenario, Bob chooses to customize the{' '}
          <strong>Default Month </strong>template.
          <br />
          <Image alt="" src={task_pane_customize_template} />
        </li>
        <li>
          The template will open in a new workbook. if you're using Excel 2013
          or later, this will be a new Excel window. Notice the template doesn't
          contain any events or tasks. Templates should always be saved without
          any events or tasks, since that data is dynamic and will be
          automatically populated in the template when a calendar is generated.
          Think of the template as a placeholder for your data. The template
          defines the layout and appearance of the calendar, but not its actual
          content. In this case, the Default Month template shows January 2019
          as a placeholder for styling the template. The date shown in the
          template is arbitrary. When a calendar is generated you can pick the
          desired date range, which could span any number of months. The
          template will be re-used for each month, each on its own worksheet.
          The Task Pane also shows <strong>Save As Template</strong>, which is
          where the template name and description can be entered before saving.
          More on this later...
          <br />
          <Image alt="" src={customize_1} />
        </li>
        <li>
          The default template doesn't contain any branding or other
          information. Since Bob wants his customers to remember where the
          calendar came from, he'll add his company logo and contact information
          to the template. Also, the colors don't match well with Bob's logo, so
          he'll adjust the colors as well. The Notes area of the Default Month
          template is a good place to add some customizations. Bob removes the
          Notes label and pastes his company logo into the bottom-right corner.{' '}
          <br />
          <Image alt="" src={customize_2} />
        </li>
        <li>
          Next, Bob adds his company's contact information to the template.
          There's some empty space to the left of the logo. The cells were
          merged in the default template, so he un-merges the cells first. The
          text color is set to match his logo.
          <br />
          <Image alt="" src={customize_3} />
        </li>
        <li>
          The blue border doesn't look good with Bob's orange and green logo, so
          he changes the border and year color using the formatting features
          included with Excel.
        </li>
        <li>
          Bob's done! Here's how his finished template looks. Snazzy!
          <br />
          <Image alt="" src={customize_5} />
        </li>
        <li>
          The final step is to save the template, using the{' '}
          <strong>Save as Template</strong> area of the Task Pane. Bob enters a
          new template name so as to not overwrite the default template. He also
          enters a description and then clicks the Save button.
          <br />
          <Image alt="" src={customize_6} style={{ width: 445 }} />
        </li>
        <li>
          After saving the template, it now appears in the template list when
          the <strong>Create Calendar</strong> button is clicked to populate the
          template with events.
          <br />
          <Image alt="" src={customize_7} style={{ width: 447 }} />
        </li>
      </ol>
      <h2>Customizing Template Layouts</h2>
      <p>
        The walk-through above covers a simple scenario. There are many other
        more advanced template customizations that can be performed.{' '}
      </p>
      <p>
        One very important thing to be aware of when customizing templates is
        how PrintableCal works with named cells.
      </p>
      <p>
        When editing a template, there are some cells that must be present in
        the template. These are designated with cell names, which are shown in
        the upper-left corner, below the Ribbon. For example, one of the cell
        names required by the Month template style is <strong>Year</strong>,
        which tells PrintableCal where to put the year number for the month
        being printed. The cell value (i.e. "2019") in the template is
        arbitrary. The important thing is that the cell name is present so
        PrintableCal can dynamically populate the value when the template is
        used to generate a calendar.{' '}
      </p>
      <p>
        Cell names can be moved elsewhere when changing the template layout. For
        example, if you prefer the date to be below the calendar, or along the
        side of the calendar, the cell name could be moved to the desired
        location.
        <br />
        <Image alt="" src={customize_advanced_1} style={{ width: 454 }} />
      </p>
      <p>
        Every template style (Week, Month, Year, etc) has cells names it
        requires. When changing the layout of a template, make sure to note
        where named cells are located before moving things around. Also, be
        aware that when cutting and pasting cells, any referenced cell names are
        not pasted. For example, if cell B5 is named "DayName1" and cut/pasted
        to cell D7, the "DayName1" reference will be lost and will need to be
        manually set to the new location.
      </p>
      <p>
        Instead of re-positioning cells by cutting and pasting, click and drag
        the cells instead. This ensures the cell name references the new
        location.
      </p>
      <p>
        When saving the template, if there are any missing cell names, a
        validation window will appear listing the problems and providing tips on
        how to correct them. Each template style has its own set of validation
        rules which must be satisfied before the template can be saved. For
        example, some template styles will ensure cell names are positioned
        properly in relation to each other.
      </p>
      <p>
        The template will only save when all validation rules have been
        satisfied. This ensures PrintableCal can properly generate calendars
        using the customized template.
      </p>
      <h2>We Can Help</h2>
      <p>
        If you don't have the time or expertise to develop a custom template, we
        provide this service at a very affordable price. Please contact us using
        the form below. Include a description of what you'd like the template to
        contain. We'll estimate how long it will take us to create the template
        and provide you a quote for this service. We're speedy-quick and since
        we developed PrintableCal, we know all the ins and outs of customizing
        templates.
      </p>
      <ContactForm />
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/How-To/Uninstall-PrintableCal"
      commentsId="commentsplus_post_160_489"
      title="Why Customize?"
      description="PrintableCal comes installed with many calendar templates that can be customized to include things like business logos, contact information, photos, and more."
      keywords="calendar template, customizable template, printable template, printable calendar, 2020 calendar, printable holidays, Gantt Chart, Excel calendar, calendar layout, event template"
      content={content}
      showContact={false}
    />
  );
};

export default Documentation;
